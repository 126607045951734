<template>
	<!-- 显示秒杀活动列表 -->
	<div class="adhibition-seckill el-content">
		<div class="tips">
			<p>1、秒杀活动执行中的只能有一个，添加的活动时间不能交叉重叠</p>
			<p>2、秒杀活动开始前5分钟或执行中的秒杀活动，详情不可编辑</p>
		</div>
		<router-link to="/adhibition/seckill/edit">
			<a-button v-has="{action:'/adhibition/seckill/edit',plat:0}" type="primary">
				<i class="ri-add-line"></i>新增活动
			</a-button>
		</router-link>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'秒杀活动名称',dataIndex:'title'},
			{title:'活动开始时间',dataIndex:'start_time'},
			{title:'活动结束日期',dataIndex:'end_time'},
			{title:'活动状态',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #is_put_away="{record}">
				<a-tag :color="record.is_put_away == 1 ?'#00CC66':'#999'">{{record.is_put_away == 1 ?'启用':'停用'}}</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/adhibition/seckill/edit',query:{id:record.id,sence:'edit'}}">
						<kd-button type="primary" 
							title="编辑" 
							icon="ri-edit-line" 
							v-has="{action:'/adhibition/seckill/edit',plat:0}">
						</kd-button>
					</router-link>
					<router-link :to="{path:'/adhibition/seckill/edit',query:{id:record.id,sence:'session'}}">
						<kd-button type="success" 
							title="设置场次" 
							icon="ri-settings-4-line" 
							v-has="{action:'wxapp_adhibition_seckill_set_session',plat:0}">
						</kd-button>
					</router-link>
					<kd-button type="danger" 
						title="删除" 
						icon="ri-delete-bin-5-line" 
						v-has="{action:'wxapp_adhibition_seckill_del',plat:0}"  
						@click="delSeckill(record.id)">
					</kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getSeckillList(info.page,e)}"
				@change="(e)=>{getSeckillList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import seckillModel from '@/api/addons/seckill'
import commonModel from '@/api/common'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			}
		})
		getSeckillList(1,state.info.limit)

		function getSeckillList(page,limit){
			seckillModel.getSeckill(page,limit,res=>state.info = {limit,...res})
		}

		const delSeckill = id=>commonModel.deleteDataList(id,51,"确认删除该活动吗？").then(()=>{
			getSeckillList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getSeckillList,
			delSeckill
		}
	}
}
</script>

<style lang="scss">
	
</style>
