import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import moment from 'moment'
import router from '@/router'

class Seckill{
    /**
     * 获取秒杀列表数据
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getSeckill(page,limit,fn){
        let param = { page,limit }
        common.getDataList("getSpikeInfoList",param).then(res=>fn(res))
    }

    /**
     * 获取秒杀详情
     * @param {number} id 
     * @param {function} fn 
     */
    getSeckillDetail(id,fn){
        $post('getSpikeInfo',{id}).then(res=>fn(res.data))
    }

    /**
     * 新增或编辑秒杀信息
     * @param {number} param 
     */
    addOrEditSeckill(param){
        if( !param.id ) delete param.id
        param.start_time = moment(param.start_time).format("YYYY-MM-DD")
        param.end_time = moment(param.end_time).format("YYYY-MM-DD")
        $post("createOrEditSpikeInfo",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 新增或编辑秒杀场次
     * @param {object}} param 
     * @param {function} fn 
     */
    addOrEditSeckillSession(param,fn){
        if( !param.id ) delete param.id
        param.start_time = moment(param.start_time).format("HH:mm")
        param.end_time = moment(param.end_time).format("HH:mm")
        $post("addOrEditSpikeSession",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 获取秒杀场次
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getSeckillSession(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.sid ) param.sid = extra.sid
        $post('getSpikeSessionList',param).then(res=>fn(res.data))
    }

    /**
     * 获取秒杀场次商品信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getSessionGoods(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.session_id ) param.session_id = extra.session_id
        common.getDataList("getSpikeGoodsList",param).then(res=>fn(res))
    }

    /**
     * 保存场次商品信息
     * @param {object} goods 
     * @param {function} fn 
     */
    addOrEditSessionGoods(goods,fn){
        $post('addOrEditSpikeGoods',{goods}).then(res=>{
            tool.message('保存成功')
            fn(res.data)
        })
    }
}
const seckillModel = new Seckill()
export default seckillModel